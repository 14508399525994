/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeng/resources/primeng.css";
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";



.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.custom-radius {
    border-radius: 50px !important;
}
.p-button-label {
    font-weight: 400 !important;
}

::placeholder {
    color: #a2a2a2 !important;
    opacity: 1 !important;
}
.custom-input-padding {
    padding-top: 0.35rem !important;
    padding-bottom: 0.35rem !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #ffefef !important;
    border-color: #ffefef !important;
    color: rgb(109, 7, 26) !important;
    border-radius: 50px !important;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #ffefef;
    border-color: #ffefef;
    color: #2F2F3B;
    border-radius: 50px !important;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #ffefef;
    border-color: #ffefef;
    color: #2F2F3B;
    border-radius: 50px !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #6d071a !important;
    background: #6d071a !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #6d071a !important;
}

.p-dialog .p-dialog-footer button {
    margin-left: 9px !important;
    height: 41px !important;
    border-radius: 5px !important;
}

.p-splitbutton-menubutton, .p-splitbutton.p-button-rounded>.p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-menubutton {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0 !important;
    height: 40px !important;
}

.p-splitbutton .p-splitbutton-defaultbutton, .p-splitbutton.p-button-rounded>.p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-defaultbutton.p-button {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    height: 40px !important;
}

.custom-f-success {
    color: $success-color;
}
.custom-f-danger {
    color: $primary-color;
}
.logo-img{
    width: 300px;
}

.task-action-btn .action-box {
    border: 1px solid #59667a;
    background: #fff;
    border-radius: 4px;
}

.task-action-btn .action-box:hover {
    border: 1px solid #475160;
    background: #fff;
    border-radius: 5px;
}


//DatePicker CSS
.dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
}
.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}
.custom-day.focused {
    background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
    background-color: rgb(2, 117, 216);
    color: white;
}
.custom-day.faded {
    background-color: rgba(2, 117, 216, 0.5);
}
/*# sourceMappingURL=bootstrap.css.map */
.ce-block__content,
    .ce-toolbar__content {
        max-width: inherit!important;
    }

 .codex-editor__redactor { padding-bottom : 10px !important; } 

/* Personnalisation de la couleur des boutons */
.btn-purple {
    background-color: purple;
    border-color: purple;
}

.btn-purple:hover {
    background-color: darkpurple;
    border-color: darkpurple;
}